import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master--summaries"
export default class extends Controller {
  hoverTimeout = null

  hoverStart(e) {
    e.preventDefault()

    const tooltip = document.querySelector('.summaries__tooltip')
    const targetCount = Math.min(parseInt(e.target.textContent), 20)
    const tooltipWidth = 350
    const tooltipWidthOffset = 20
    
    const sidebarWidth = document.querySelector('.pcOnly.left-content').offsetWidth
    const headerHeight = document.querySelector('.breadcrumb-container').offsetHeight
    const mouseX = e.clientX
    const mouseY = e.clientY

    // Tooltipの右端がウィンドウの右端を超える場合はTooltipを左側に表示
    if (mouseX + tooltipWidthOffset + tooltipWidth > window.innerWidth) {
      tooltip.style.left = `${mouseX - sidebarWidth - tooltipWidth - tooltipWidthOffset}px`
    } else {
      tooltip.style.left = `${mouseX - sidebarWidth + tooltipWidthOffset}px`
    }

    tooltip.style.top = `${window.scrollY + mouseY - headerHeight - tooltipWidthOffset}px`
    tooltip.innerHTML = targetCount === 0 ? '<div>No Data</div>' : '<div>Loading...</div>'
    tooltip.style.display = 'flex'

    if (targetCount === 0) {
      return
    }

    this.hoverTimeout = setTimeout(() => {
      this.executeAction(e)
    }, 1000)
  }

  hoverEnd(e) {
    e.preventDefault()

    clearTimeout(this.hoverTimeout)
    const tooltip = document.querySelector('.summaries__tooltip')
    tooltip.style.display = 'none'
  }

  executeAction(event) {
    const dataset = event.target.dataset

    fetch(`/api/v1/entries?date=${dataset.date}&event_type=${dataset.eventType}`)
      .then(response => response.json())
      .then(data => {
        const content = data.map(entry => {
          const date = new Date(entry.event_date)
          return `<div class='entry'>
            <div class='entry_type'>${entry.facility_type}</div>
            <div class='entry_name'>${entry.daycare_center_name}</div>
            <div class='entry_time'>
              ${date.getHours().toString().padStart(2, '0')}:
              ${date.getMinutes().toString().padStart(2, '0')}:
              ${date.getSeconds().toString().padStart(2, '0')}
            </div>
          </div>`
        }).join('<hr>')

        const tooltip = document.querySelector('.summaries__tooltip')
        tooltip.innerHTML = `<div class='wrapper'><div class='description'>※最新の20件のみ表示</div><hr>${content}<hr></div>`

        // tooltip内容代入後にwindowの下端を超える場合は、Tooltipの下端を画面の下端に合わせる
        const tooltipHeight = tooltip.offsetHeight
        const tooltipHeightOffset = 30
        const windowsHeight = window.innerHeight
        const headerHeight = document.querySelector('.breadcrumb-container').offsetHeight
        if (event.clientY + tooltipHeight > windowsHeight - tooltipHeightOffset) {
          tooltip.style.top = `${window.scrollY + windowsHeight - headerHeight - tooltipHeight - tooltipHeightOffset}px`
        }
      })
      .catch(error => {
        console.log(error)
        const tooltip = document.querySelector('.summaries__tooltip')
        tooltip.innerHTML = '<div>Error loading data</div>'
      })
  }
}
